<template>
  <v-container class="pa-8 mb-10">
    <v-row justify="center">
      <v-card max-width="700px" class="px-10 pt-2">
        <v-card-title justify="center" >
          <span class="mb-2"
            ><p class="font-weight-bold" Roboto>Upload Product</p></span
          >
        </v-card-title>
        <br />

        <v-card-text style="background:#E1E1E1; width:100%;">
          <v-form>
            <v-row class="px-10">
              <v-col sm="6" md="5" class="d-flex flex-row">
                <v-select
                  v-model="selectedCatyegory"
                  :items="category"
                  :rules="[(v) => !!v || 'category is required']"
                  required
                  label="category"
                  outlined
                  @change="onSelectCategory"
                  dense
                ></v-select>

                <v-btn
                  color="#854FFF"
                  fab
                  small
                  dark
                  class="ml-2"
                  @click="dialog1 = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5" class="pl-3">
                <v-text-field
                  v-model="title"
                  label="Title"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || 'Product Title is required']"
                ></v-text-field>
              </v-col>

              <v-col sm="6" md="5" class="d-flex flex-row">
                <!-- <v-select
                  v-model="selectedSubCatyegory"
                  :items="sub_category"
                  label="Sub category"
                  outlined
                  dense
                  @change="onSelectSubCategory()"
                ></v-select> -->

                <v-select
                  v-model="selectedSubCatyegory"
                  :items="sub_category"
                  :rules="[(v) => !!v || 'category is required']"
                  required
                  label="Sub category"
                  outlined
                  @change="onSelectSubCategory"
                  dense
                ></v-select>

                <v-btn
                  color="#854FFF"
                  fab
                  small
                  dark
                  class="ml-2"
                  @click="dialog2 = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5">
                <v-text-field
                  v-model="brand"
                  label="Product Brand"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col sm="6" md="5" class="d-flex flex-row">
                <v-select
                  v-model="selectedSubSubCategory"
                  :items="sub_sub_category"
                  item-text="title"
                  label="Sub Sub category"
                  outlined
                  dense
                ></v-select>
                <v-btn
                  color="#854FFF"
                  fab
                  small
                  dark
                  class="ml-2"
                  @click="dialog3 = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5">
                <v-text-field
                  v-model="price"
                  label="Product Price"
                  required
                  :rules="[(v) => !!v || 'Price is required']"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col sm="6" md="5">
                <v-text-field
                  v-model="amount"
                  label="Discount Amount "
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5">
                <v-text-field
                  v-model="discount_type"
                  label="Discount Type"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col sm="6" md="5">
                <v-datetime-picker
                  label="Discount End Date"
                  v-model="discount_end_date"
                ></v-datetime-picker>

                <br />

                <v-text-field
                  v-model="point"
                  label="Product Point"
                  outlined
                  dense
                ></v-text-field>

                <br />

                <v-datetime-picker
                  label="Point End Date"
                  v-model="point_end_date"
                ></v-datetime-picker>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Product Description"
                  height="220px"
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="px-8">
              <v-col cols="12">
                <v-file-input
                  color="deep-purple accent-4"
                  counter
                  label="File input"
                  multiple
                  placeholder="Select your files"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  v-model="image"
                  :rules="[(value) => value.length != 0 || 'Image is required']"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                      >{{ text }}</v-chip
                    >

                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                      >+{{ image.length - 2 }} File(s)</span
                    >
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-for="(feature, index) in key_features"
                  :key="index"
                  v-model="key_features[index]"
                  label="Product key features"
                  outlined
                  dense
                ></v-text-field>
                <v-btn @click="addFeatures" color="#854FFF">Add Feature</v-btn>
              </v-col>
            </v-row>
            <div class="pb-10">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#854FFF" @click="save">Save</v-btn>
                <v-btn color="pink" @click="dialog1">Cancel</v-btn>
                <!-- <v-btn color="blue darken-1" text @click="updateUser">Save</v-btn>
                <v-btn color="red" text @click="close">Cancel</v-btn> -->
              </v-card-actions>
            </div>
          </v-form>
        </v-card-text>
        <v-dialog v-model="dialog1" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="font-weight-bold">Add Category</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <p class="mt-5 ml-3 font-weight-bold">Category</p>
                    <v-text-field
                      class="mt-5 ml-2"
                      label="Category"
                      outlined
                      v-model="new_category"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <div class="pb-10">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#854FFF" @click="saveCategory">Save</v-btn>
                <v-btn color="pink" @click="dialog1 = false">Cancel</v-btn>
                <!-- <v-btn color="blue darken-1" text @click="updateUser">Save</v-btn>
                <v-btn color="red" text @click="close">Cancel</v-btn> -->
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="font-weight-bold">Add Sub category</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <p class="mt-5 ml-3 font-weight-bold">Sub Category</p>
                    <v-text-field
                      class="mt-5 ml-2"
                      label="Sub Category"
                      outlined
                      v-model="new_sub_category"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <div class="pb-10">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#854FFF" @click="AddSubCategory">Save</v-btn>
                <v-btn color="pink" @click="dialog2 = false">Cancel</v-btn>
                <!-- <v-btn color="blue darken-1" text @click="updateUser">Save</v-btn>
                <v-btn color="red" text @click="close">Cancel</v-btn> -->
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog3" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="font-weight-bold">Sub Sub Category</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <p class="mt-5 ml-3 font-weight-bold">Sub Sub Category</p>
                    <v-text-field
                      class="mt-5 ml-2"
                      label="Sub Sub Category"
                      outlined
                      v-model="new_sub_sub_category"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <div class="pb-10">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#854FFF" @click="addSubSubCategory">Save</v-btn>
                <v-btn color="pink" @click="dialog3 = false">Cancel</v-btn>
                <!-- <v-btn color="blue darken-1" text @click="updateUser">Save</v-btn>
                <v-btn color="red" text @click="close">Cancel</v-btn> -->
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </v-card>
      <v-alert :value="alert" type="success" dark border="top">
        Product Added Successfully!
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    alert: false,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,
    new_sub_sub_category: "",

    category: [],
    selectedCatyegory: "",

    sub_category: [],
    selectedSubCatyegory: "",

    sub_sub_category: [],
    selectedSubSubCategory: "",

    title: "",
    brand: "",
    image: [],
    description: "",
    key_features: [],
    quantity: "",
    properties: true,
    point: "",
    price: "",
    discount_type: "",
    amount: "",
    discount_end_date: "",
    point_end_date: "",
    order: "",
  }),

  methods: {
    addFeatures() {
      this.key_features.push("");
    },

    save() {
      // console.log("ashtesi");
      let formData = new FormData();

      formData.append("category", this.selectedCatyegory);
      formData.append("sub_category", this.selectedSubCatyegory);
      formData.append("sub_sub_category", this.selectedSubSubCategory);
      formData.append("title", this.title);
      formData.append("brand", this.brand);
      formData.append("description", this.description);
      formData.append("key_features", this.key_features);
      formData.append("quantity", this.quantity);
      formData.append("price", this.price);
      formData.append("point", this.point);
      formData.append("amount", this.amount);

      formData.append("discount_end_date", this.discount_end_date);
      formData.append("point_end_date", this.point_end_date);

      for (var i = 0; i < this.image.length; i++) {
        let file = this.image[i];

        formData.append("images[" + i + "]", file);
      }

      for (var pair of formData.entries()) {
        // console.log(pair[0] + ", " + pair[1]);
      }

      // await axios.post("product/add_product/", formData, {
      //   headers: { "Content-Type": "multipart/form-data" },
      // });

      axios
        .post("product/add_product/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.alert = true;
          this.$router.push("/supportdashboard/pages/product");
        });
    },

    saveCategory() {
      this.category.push(this.new_category);
      this.dialog1 = false;
    },

    AddSubCategory() {
      this.sub_category.push(this.new_sub_category);
      this.dialog2 = false;
    },

    addSubSubCategory() {
      this.sub_sub_category.push(this.new_sub_sub_category);
      this.dialog3 = false;
    },
    close() {
      // this.$router.push("/supportdashboard/pages/product");
    },

    saveEdit() {
      axios
        .post("category/insert/", {
          category: this.category,
          sub_category: this.sub_category,
          sub_sub_category: this.sub_sub_category,
        })
        .then((response) => {
          // console.log("Clicked");
          // console.log(response.data);
          this.dialog1 = false;
          this.dialog2 = false;
          this.dialog3 = false;
        });
    },

    getAllCastegory() {
      axios.get("category/categories/").then((response) => {
        this.category = response.data;
      });
    },

    onSelectCategory() {
      axios
        .post(`category/subcategories/`, { name: this.selectedCatyegory })
        .then((response) => {
          this.sub_category = response.data;
        });
    },

    onSelectSubCategory() {
     axios
        .post(`category/subsubcategories/`, { name: this.selectedSubCatyegory })
        .then((response) => {
          this.sub_sub_category = response.data;
        });
    },
  },
  mounted() {
    this.getAllCastegory();
  },
};
</script>

<style>
</style>